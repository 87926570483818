/* 首页模块页面路由 */

let homePage = [
  {
    path: '/home',
    name: 'home',
    meta: { title: '融跃教育' },
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/course',
    name: 'course',
    meta: { title: '所有课程更多' },
    component: () => import('@/views/course/index.vue')
  },
  {
    path: '/consult/combos',
    name: 'combos',
    meta: { title: 'combos图书' },
    component: () => import('@/views/consult/common/combo.vue')
  },
  {
    path: '/bookList',
    name: 'bookList',
    meta: { title: '图书book' },
    component: () => import('@/views/courseCenter/bookList.vue')
  },
  {
    path: '/courseCenter',
    name: 'courseCenter',
    meta: { title: '所有课程' },
    component: () => import('@/views/courseCenter/index.vue')
  },
  {
    path: '/exam_practice',
    name: 'exam_practice',
    meta: { title: '题库列表' },
    component: () => import('@/views/exam_practice/index.vue')
  },
  {
    path: '/lesson_live',
    name: 'lesson_live',
    meta: { title: '直播课程' },
    component: () => import('@/views/lesson_live/index.vue')
  },
  {
    path: '/article_news',
    name: 'article_news',
    meta: { title: '咨询' },
    component: () => import('@/views/article_news/index.vue')
  },
  {
    path: '/download_datum',
    name: 'download_datum',
    meta: { title: '资料下载' },
    component: () => import('@/views/download/index.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    meta: { title: '线下活动' },
    component: () => import('@/views/xa_activity/index.vue')
  },
  {
    path: '/audition',
    name: 'audition',
    meta: { title: '试听' },
    component: () => import('@/views/audition/index.vue')
  },
  {
    path: '/question',
    name: 'question',
    meta: { title: '平台答疑' },
    component: () => import('@/views/consult/question/index.vue')
  },
  {
    path: '/homes_tabs',
    name: 'homes_tabs',
    meta: { title: '会员中心' },
    component: () => import('@/views/homes_tabs/index.vue')
  },
  {
    path: '/handpick_kc',
    name: 'handpick_kc',
    meta: { title: '课程详情' },
    component: () => import('@/views/homes_comms/handpick_kc/index.vue')
  },
  {
    path: '/ShiTingDetail',
    name: 'ShiTingDetail',
    meta: { title: '试听详情' },
    component: () => import('@/views/audition/ShiTingDetail.vue')
  },
  {
    path: '/book_detail',
    name: 'book_detail',
    meta: { title: '图书详情' },
    component: () => import('@/views/homes_comms/book_detail/index.vue')
  },
  {
    path: '/hot_live',
    name: 'hot_live',
    meta: { title: '直播详情页' },
    component: () => import('@/views/homes_comms/hot_live/index.vue')
  },
  {
    path: '/down_data',
    name: 'down_data',
    meta: { title: '资料下载详情' },
    component: () => import('@/views/homes_comms/down_data/index.vue')
  },
  {
    path: '/top_teacher',
    name: 'top_teacher',
    meta: { title: '名师' },
    component: () => import('@/views/homes_comms/top_teacher/index.vue')
  },
  {
    path: '/teacher_team',
    name: 'teacher_team',
    meta: { title: '名师团队' },
    component: () => import('@/views/homes_comms/teacher_team/index.vue')
  },
  {
    path: '/exam_practice/CFA/questionbank',
    name: 'questionbank',
    meta: { title: '题库列表' },
    component: () => import('@/views/exam_practice/CFA/questionbank.vue')
  },
  {
    path: '/exam_practice/CFA/cfa_tiku_pratice',
    name: 'cfa_tiku_pratice',
    meta: { title: 'cfa_tiku_pratice做题' },
    component: () => import('@/views/exam_practice/CFA/cfa_tiku_pratice.vue')
  },
  {
    path: '/exam_practice/CFA/CFAReport',
    name: 'CFAReport',
    meta: { title: 'cfa题库报告页' },
    component: () => import('@/views/exam_practice/CFA/CFAReport.vue')
  },
  {
    path: '/exam_practice/FRM/frmQuestionbank',
    name: 'frmQuestionbank',
    meta: { title: '题库列表' },
    component: () => import('@/views/exam_practice/FRM/frmQuestionbank.vue')
  },
  {
    path: '/exam_practice/FRM',
    name: 'frmPractice',
    meta: { title: 'frmPractice' },
    component: () => import('@/views/exam_practice/FRM/index.vue')
  },
  {
    path: '/exam_practice/FRM/frmReport',
    name: 'frmReport',
    meta: { title: 'frmReport报告页' },
    component: () => import('@/views/exam_practice/FRM/frmReport.vue')
  },
  {
    path: '/exam_practice/FRM/frmAnalysis',
    name: 'frmAnalysis',
    meta: { title: 'frmAnalysis解析页' },
    component: () => import('@/views/exam_practice/FRM/frmAnalysis.vue')
  },
  {
    path: '/tiKuMoke/FRM/tikuMockLists',
    name: 'tikuMockLists',
    meta: { title: 'tikuMockLists模拟考试列表' },
    component: () => import('@/views/tiKuMoke/FRM/tikuMockLists.vue')
  },
  {
    path: '/tiKuMoke/FRM/frmtestReport',
    name: 'tikufrmtestReport',
    meta: { title: 'tiku模拟考试报告' },
    component: () => import('@/views/tiKuMoke/FRM/frmtestReport.vue')
  },
  {
    path: '/tiKuMoke/FRM/frmMockAnalysis',
    name: 'tikufrmMockAnalysis',
    meta: { title: 'tiku模拟考试解析' },
    component: () => import('@/views/tiKuMoke/FRM/frmMockAnalysis.vue')
  },
  {
    path: '/computerPractice/FRM/Frmcomputer',
    name: 'Frmcomputer',
    meta: { title: 'Frmcomputer机考列表' },
    component: () => import('@/views/computerPractice/FRM/Frmcomputer.vue')
  },
  {
    path: '/computerPractice/FRM/frmAnalysis',
    name: 'frmAnalysis',
    meta: { title: 'frmAnalysis机考解析' },
    component: () => import('@/views/computerPractice/FRM/frmAnalysis.vue')
  },
  {
    path: '/computerPractice/FRM/FrmockExam',
    name: 'FrmockExam',
    meta: { title: 'FrmockExam机考答题' },
    component: () => import('@/views/computerPractice/FRM/FrmockExam.vue')
  },
  {
    path: '/computerPractice/FRM/frmtestReport',
    name: 'frmtestReport',
    meta: { title: 'frmtestReport机考报告' },
    component: () => import('@/views/computerPractice/FRM/frmtestReport.vue')
  },
  {
    path: '/tiKuMoke/CFA/tikuMockLists',
    name: 'tikuMockLists',
    meta: { title: 'tikuMockLists模拟考试列表' },
    component: () => import('@/views/tiKuMoke/CFA/tikuMockLists.vue')
  },
  {
    path: '/tiKuMoke/CFA/cfatestReport',
    name: 'cfatestReport',
    meta: { title: 'tiku模拟考试报告' },
    component: () => import('@/views/tiKuMoke/CFA/cfatestReport.vue')
  },
  {
    path: '/tiKuMoke/CFA/cfaMockAnalysis',
    name: 'cfaMockAnalysis',
    meta: { title: 'tiku模拟考试解析' },
    component: () => import('@/views/tiKuMoke/CFA/cfaMockAnalysis.vue')
  },
  {
    path: '/computerPractice/CFA/cfaComputerMenu',
    name: 'cfaComputerMenu',
    meta: { title: 'CFA机考中心' },
    component: () => import('@/views/computerPractice/CFA/cfaComputerMenu.vue')
  },
  {
    path: '/computerPractice/CFA/cfaComputerReport',
    name: 'cfaComputerReport',
    meta: { title: 'CFA机考报告' },
    component: () =>
      import('@/views/computerPractice/CFA/cfaComputerReport.vue')
  },
  {
    path: '/computerPractice/CFA/cfaComputerAnalysis',
    name: 'cfaComputerAnalysis',
    meta: { title: 'CFA机考解析' },
    component: () =>
      import('@/views/computerPractice/CFA/cfaComputerAnalysis.vue')
  },
  {
    path: '/tiKuMoke/CPA/tikuMockLists',
    name: 'tikuMockLists',
    meta: { title: 'tikuMockLists模拟考试列表' },
    component: () => import('@/views/tiKuMoke/CPA/tikuMockLists.vue')
  },
  {
    path: '/tiKuMoke/CPA/cpaTikuReport',
    name: 'cpaTikuReport',
    meta: { title: 'cpaTikuReport模拟考试报告' },
    component: () => import('@/views/tiKuMoke/CPA/cpaTikuReport.vue')
  },
  {
    path: '/tiKuMoke/CPA/cpaMockAnalysis',
    name: 'cpaMockAnalysis',
    meta: { title: 'tiku模拟考试解析' },
    component: () => import('@/views/tiKuMoke/CPA/cpaMockAnalysis.vue')
  },
  {
    path: '/computerPractice/CPA/computerExamLists',
    name: 'computerExamLists',
    meta: { title: 'CPA机考中心' },
    component: () => import('@/views/computerPractice/CPA/computerExamLists.vue')
  },
  {
    path: '/computerPractice/CPA/cpaComputerReport',
    name: 'cpaComputerReport',
    meta: { title: 'CPA机考报告' },
    component: () => import('@/views/computerPractice/CPA/cpaComputerReport.vue')
  },
  {
    path: '/computerPractice/CPA/cpaComputerAnalysis',
    name: 'cpaComputerAnalysis',
    meta: { title: 'CPA机考解析' },
    component: () => import('@/views/computerPractice/CPA/cpaComputerAnalysis.vue')
  },
  {
    path: '/exam_practice/ACCA/AccaQuestionbank',
    name: 'ACCAQuestionbank',
    meta: { title: 'ACCA列表页' },
    component: () => import('@/views/exam_practice/ACCA/AccaQuestionbank.vue')
  },
  {
    path: '/exam_practice/ACCA/ACCAreport',
    name: 'ACCAreport',
    meta: { title: 'Acca题库报告页' },
    component: () => import('@/views/exam_practice/ACCA/ACCAreport.vue')
  },
  {
    path: '/exam_practice/ACCA/ACCAnalysis',
    name: 'ACCAnalysis',
    meta: { title: 'ACCAnalysis' },
    component: () => import('@/views/exam_practice/ACCA/ACCAnalysis.vue')
  },
  {
    path: '/exam_practice/CPA/cpaPractice',
    name: 'CPAPage',
    meta: { title: 'CPAPage' },
    component: () => import('@/views/exam_practice/CPA/cpaPractice.vue')
  },
  {
    path: '/exam_practice/CPA/cpaReport',
    name: 'cpaReport',
    meta: { title: 'cpaReport报告' },
    component: () => import('@/views/exam_practice/CPA/cpaReport.vue')
  },
  {
    path: '/exam_practice/CPA/CPAnalysis',
    name: 'CPAnalysis',
    meta: { title: 'CPAnalysis解析' },
    component: () => import('@/views/exam_practice/CPA/CPAnalysis.vue')
  },
  {
    path: '/exam_practice/CPA/cpaQuestionbank',
    name: 'cpaQuestionbank',
    meta: { title: 'cpaQuestionbank题库' },
    component: () => import('@/views/exam_practice/CPA/cpaQuestionbank.vue')
  },
  {
    path: '/exam_practice/CMA/cmaQuestionbank',
    name: 'cmaQuestionbank',
    meta: { title: 'cmaQuestionbank列表页' },
    component: () => import('@/views/exam_practice/CMA/cmaQuestionbank.vue')
  },
  {
    path: '/exam_practice/CMA/cmaReport',
    name: 'cmaReport',
    meta: { title: 'CMA题库报告页' },
    component: () => import('@/views/exam_practice/CMA/cmaReport.vue')
  },
  {
    path: '/exam_practice/CMA/cmaAnalysis',
    name: 'cmaAnalysis',
    meta: { title: 'CMA题库解析' },
    component: () => import('@/views/exam_practice/CMA/cmaAnalysis.vue')
  },
  {
    path: '/tiKuMoke/CMA/tikuMockLists',
    name: 'tikuMockLists',
    meta: { title: 'tikuMockLists模拟考试列表' },
    component: () => import('@/views/tiKuMoke/CMA/tikuMockLists.vue')
  },
  {
    path: '/tiKuMoke/CMA/cmatestReport',
    name: 'cmatestReport',
    meta: { title: 'tiku模拟考试报告' },
    component: () => import('@/views/tiKuMoke/CMA/cmatestReport.vue')
  },
  {
    path: '/tiKuMoke/CMA/cmaMockAnalysis',
    name: 'cmaMockAnalysis',
    meta: { title: 'tiku模拟考试解析' },
    component: () => import('@/views/tiKuMoke/CMA/cmaMockAnalysis.vue')
  },
  {
    path: '/computerPractice/CMA/computerExamLists',
    name: 'computerExamLists',
    meta: { title: 'computerExamLists列表' },
    component: () =>
      import('@/views/computerPractice/CMA/computerExamLists.vue')
  },
  {
    path: '/computerPractice/CMA/cmaComputerReport',
    name: 'cmaComputerReport',
    meta: { title: 'cmaComputerReport报告' },
    component: () =>
      import('@/views/computerPractice/CMA/cmaComputerReport.vue')
  },
  {
    path: '/computerPractice/CMA/cmaComputerAnalysis',
    name: 'cmaComputerAnalysis',
    meta: { title: 'cmaComputerAnalysis解析' },
    component: () =>
      import('@/views/computerPractice/CMA/cmaComputerAnalysis.vue')
  },
  {
    path: '/exam_practice/Python/pythonPractice',
    name: 'pythonPractice',
    meta: { title: 'pythonPractice练习' },
    component: () => import('@/views/exam_practice/Python/pythonPractice.vue')
  },
  {
    path: '/exam_practice/Python/Python_questionbank',
    name: 'Python_questionbank',
    meta: { title: 'Python_questionbank列表页' },
    component: () =>
      import('@/views/exam_practice/Python/Python_questionbank.vue')
  },
  {
    path: '/exam_practice/Python/PythonReport',
    name: 'PythonReport',
    meta: { title: 'PythonReport报告页' },
    component: () => import('@/views/exam_practice/Python/PythonReport.vue')
  },
  {
    path: '/exam_practice/Python/PythonAnalysis',
    name: 'PythonAnalysis',
    meta: { title: 'PythonAnalysis解析页' },
    component: () => import('@/views/exam_practice/Python/PythonAnalysis.vue')
  },
  {
    path: '/tiKuMoke/Python/tikuMockLists',
    name: 'tikuMockLists',
    meta: { title: 'tikuMockLists模拟考试列表' },
    component: () => import('@/views/tiKuMoke/Python/tikuMockLists.vue')
  },
  {
    path: '/tiKuMoke/Python/PythontestReport',
    name: 'PythontestReport',
    meta: { title: 'tiku模拟考试报告' },
    component: () => import('@/views/tiKuMoke/Python/PythontestReport.vue')
  },
  {
    path: '/tiKuMoke/Python/PythonMockAnalysis',
    name: 'PythonMockAnalysis',
    meta: { title: 'tiku模拟考试解析' },
    component: () => import('@/views/tiKuMoke/Python/PythonMockAnalysis.vue')
  },
  {
    path: '/computerPractice/Python/Pythoncomputer',
    name: 'Pythoncomputer',
    meta: { title: 'Pythoncomputer机考列表' },
    component: () => import('@/views/computerPractice/Python/Pythoncomputer.vue')
  },
  {
    path: '/computerPractice/Python/PythonAnalysis',
    name: 'PythonAnalysis',
    meta: { title: 'PythonAnalysis机考解析' },
    component: () => import('@/views/computerPractice/Python/PythonAnalysis.vue')
  },
  {
    path: '/computerPractice/Python/PythonmockExam',
    name: 'PythonmockExam',
    meta: { title: 'PythonmockExam机考答题' },
    component: () => import('@/views/computerPractice/Python/PythonmockExam.vue')
  },
  {
    path: '/computerPractice/Python/PythontestReport',
    name: 'PythontestReport',
    meta: { title: 'PythonmtestReport机考报告' },
    component: () => import('@/views/computerPractice/Python/PythontestReport.vue')
  },
  {
    path: '/exam_practice/Accountancy_qualification',
    name: 'Accountancy_qualification_Page',
    meta: { title: 'Accountancy_qualificationPage页' },
    component: () =>
      import('@/views/exam_practice/Accountancy_qualification/index.vue')
  },
  {
    path: '/exam_practice/Accountancy_qualification/questionbank',
    name: 'Accountancy_qualification_questionbank',
    meta: { title: 'questionbank列表页' },
    component: () =>
      import('@/views/exam_practice/Accountancy_qualification/questionbank.vue')
  },
  {
    path: '/exam_practice/errorList',
    name: 'errorList',
    meta: { title: 'errorList错题集' },
    component: () => import('@/views/exam_practice/errorList/index.vue')
  },
  {
    path: '/exam_practice/errorList/collectionDetails',
    name: 'collectionDetails',
    meta: { title: 'errorList错题集' },
    component: () =>
      import('@/views/exam_practice/errorList/collectionDetails.vue')
  },
  {
    path: '/exam_practice/errorReport',
    name: 'errorReport',
    meta: { title: '错题报告页' },
    component: () => import('@/views/exam_practice/errorList/errorReport.vue')
  },
  {
    path: '/exam_practice/errorAnalysis',
    name: 'errorAnalysis',
    meta: { title: '错题解析页' },
    component: () => import('@/views/exam_practice/errorList/errorAnalysis.vue')
  },
  {
    path: '/exam_practice/proErrorClass',
    name: 'proErrorClass',
    meta: { title: '错题列表' },
    component: () => import('@/views/exam_practice/errorList/proErrorClass.vue')
  },
  {
    path: '/exam_practice/collectionMenu',
    name: 'collectionMenu',
    meta: { title: '收藏列表' },
    component: () =>
      import('@/views/exam_practice/errorList/collectionMenu.vue')
  },
  {
    path: '/exam_practice/collectList',
    name: 'collectList',
    meta: { title: 'collectList收藏集' },
    component: () => import('@/views/exam_practice/collectList/index.vue')
  },
  {
    path: '/exam_practice/smartTest',
    name: 'smartTest',
    meta: { title: 'smartTest智能组卷' },
    component: () => import('@/views/exam_practice/smartTest.vue')
  },
  {
    path: '/lineCourseList',
    name: 'lineCourseList',
    meta: { title: 'lineCourseList长线课程' },
    component: () => import('@/views/lineCourseList/index.vue')
  },
  {
    path: '/confirm_order_combo',
    name: 'confirm_order_combo',
    meta: { title: '订单详情' },
    component: () => import('@/views/common/confirm_order_combo.vue')
  },
  {
    path: '/order_Book',
    name: 'order_Book',
    meta: { title: '图书订单' },
    component: () => import('@/views/common/order_Book.vue')
  },
  {
    path: '/pay_combo',
    name: 'pay_combo',
    meta: { title: '提交订单' },
    component: () => import('@/views/common/pay_combo.vue')
  }
]

export default {
  homePage
}
