/* 一级页面路由 */

let stairRouter = [
  {
    path: '/exam_practice/CFA/CFAnalysis',
    name: 'CFAnalysis',
    meta: { title: 'CFAnalysis' },
    component: () => import('@/views/exam_practice/CFA/CFAnalysis.vue')
  },
  {
    path: '/exam_practice/Acca',
    name: 'AccaPage',
    meta: { title: 'AccaPage' },
    component: () => import('@/views/exam_practice/ACCA/index.vue')
  },
  {
    path: '/exam_practice/Acca/AccaComputer',
    name: 'AccaComputer',
    meta: { title: 'AccaComputer' },
    component: () => import('@/views/exam_practice/ACCA/AccaComputer.vue')
  },
  {
    path: '/exam_practice/Acca/AccaComputerAna',
    name: 'AccaComputerAna',
    meta: { title: 'AccaComputerAna' },
    component: () => import('@/views/exam_practice/ACCA/AccaComputerAna.vue')
  },
  {
    path: '/computerPractice/CPA/CPA_login',
    name: 'CPA_login',
    meta: { title: 'CPA_login机考登录' },
    component: () => import('@/views/computerPractice/CPA/CPA_login.vue')
  },
  {
    path: '/computerPractice/CPA/CPA_rules',
    name: 'CPA_rules',
    meta: { title: 'CPA_rules机考守则' },
    component: () => import('@/views/computerPractice/CPA/CPA_rules.vue')
  },
  {
    path: '/computerPractice/CPA/CPA_jksm',
    name: 'CPA_jksm',
    meta: { title: 'CPA_jksm机考守则' },
    component: () => import('@/views/computerPractice/CPA/CPA_jksm.vue')
  },
  // {
  //   path: '/exam_practice/CMA',
  //   name: 'CMAPage',
  //   meta: { title: 'CMAPage' },
  //   component: () => import('@/views/exam_practice/CMA/index.vue')
  // },
  {
    path: '/lineCourseList/course_play_cc2',
    name: 'course_play_cc2',
    meta: { title: 'course_play_cc2长线课程视频观看' },
    component: () => import('@/views/lineCourseList/common/course_play_cc2.vue')
  },
  {
    path: '/lineCourseList/course_play_ali',
    name: 'course_play_ali',
    meta: { title: 'course_play_ali长线课程视频观看' },
    component: () => import('@/views/lineCourseList/common/course_play_ali.vue')
  },
  {
    path: '/newzhike/tutorial',
    name: 'tutorial',
    meta: { title: 'tutorial模拟&机考测试教程' },
    component: () => import('@/views/newzhike/mockList/tutorial.vue')
  },
  {
    path: '/newzhike/mockExam',
    name: 'mockExam',
    meta: { title: 'mockExam模拟&机考做题' },
    component: () => import('@/views/newzhike/mockList/mockExam.vue')
  },
  {
    path: '/newzhike/FRM/FrmockExam',
    name: 'FrmockExam',
    meta: { title: 'FrmockExam-FRM模拟&机考做题' },
    component: () => import('@/views/newzhike/mockList/FRM/FrmockExam.vue')
  },
  {
    path: '/tiKuMoke/FRM/FrmockExam',
    name: 'tiKuFrmockExam',
    meta: { title: 'tiKuFrmockExam模拟做题' },
    component: () => import('@/views/tiKuMoke/FRM/FrmockExam.vue')
  },
  {
    path: '/tiKuMoke/CFA/Keycode',
    name: 'Keycode',
    meta: { title: 'Keycode' },
    component: () => import('@/views/tiKuMoke/CFA/Keycode.vue')
  },
  {
    path: '/computerPractice/CFA/Keycode',
    name: 'Keycode',
    meta: { title: 'Keycode' },
    component: () => import('@/views/computerPractice/CFA/Keycode.vue')
  },
  {
    path: '/tiKuMoke/CFA/simulatedExam',
    name: 'simulatedExam',
    meta: { title: 'simulatedExam' },
    component: () => import('@/views/tiKuMoke/CFA/simulatedExam.vue')
  },
  {
    path: '/tiKuMoke/Python/pythonPractice',
    name: 'pythonPractice',
    meta: { title: 'pythonPractice' },
    component: () => import('@/views/tiKuMoke/Python/PythonMockExam.vue')
  },
  {
    path: '/computerPractice/CFA/cfaComputerExam',
    name: 'cfaComputerExam',
    meta: { title: 'CFA机考答题' },
    component: () => import('@/views/computerPractice/CFA/cfaComputerExam.vue')
  },
  {
    path: '/exam_practice/CMA/cmaPractice',
    name: 'cmaPractice',
    meta: { title: 'cmaPractice答题' },
    component: () => import('@/views/exam_practice/CMA/cmaPractice.vue')
  },
  {
    path: '/tiKuMoke/CMA/cmaTextExam',
    name: 'cmaTextExam',
    meta: { title: 'cmaTextExam答题' },
    component: () => import('@/views/tiKuMoke/CMA/cmaTextExam.vue')
  },
  {
    path: '/computerPractice/CMA/cmaComputerExam',
    name: 'cmaComputerExam',
    meta: { title: 'cmaComputerExam答题' },
    component: () => import('@/views/computerPractice/CMA/cmaComputerExam.vue')
  },
  {
    path: '/tiKuMoke/CPA/cpaTikuExam',
    name: 'cpaTikuExam',
    meta: { title: 'cpaTikuExam模拟考试' },
    component: () => import('@/views/tiKuMoke/CPA/cpaTikuExam.vue')
  },
  {
    path: '/computerPractice/CPA/cpaComputerExam',
    name: 'cpaComputerExam',
    meta: { title: 'cpaComputerExam模拟考试' },
    component: () => import('@/views/computerPractice/CPA/cpaComputerExam.vue')
  },
  {
    path: '/newzhike/playback',
    name: 'playback',
    meta: { title: 'playback' },
    component: () => import('@/views/newzhike/studyPlan/playback.vue')
  },
  {
    path: '/consult/common/institute',
    name: 'institute',
    meta: { title: 'institute' },
    component: () => import('@/views/consult/common/institute.vue')
  },
  {
    path: '/consult/common/FRM_Institute_Report',
    name: 'FRM_Institute_Report',
    meta: { title: 'FRM_Institute_Report' },
    component: () => import('@/views/consult/common/FRM_Institute_Report.vue')
  },
  {
    path: '/consult/common/CFA_Institute_Report',
    name: 'CFA_Institute_Report',
    meta: { title: 'CFA_Institute_Report' },
    component: () => import('@/views/consult/common/CFA_Institute_Report.vue')
  },
  {
    path: '/consult/common/ACCA_Institute_Report',
    name: 'ACCA_Institute_Report',
    meta: { title: 'ACCA_Institute_Report' },
    component: () => import('@/views/consult/common/ACCA_Institute_Report.vue')
  }
]

export default {
  stairRouter
}
